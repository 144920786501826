// Node Modules
import React from "react";
import { graphql } from "gatsby"
import SimpleReactLightbox from 'simple-react-lightbox'
import SDArticle from "../components/StructuredData/SDArticle"
import ReactMarkdown from 'react-markdown'
import { SRLWrapper } from "simple-react-lightbox"
import Img from 'gatsby-image';
// Components
import Layout from '../components/Layout/Layout'
import Seo from "../components/Seo"
import ModalGallery from '../components/ModalGallery'
import ProgressGallery from '../components/ProgressGallery'
import WidgetProjects from '../components/WidgetProjects'
import HeroProject from "../components/HeroProject";
import ProjectTabs from "../components/ProjectTabs";

const ProjectPost = ({ data, location }) => {

  const project = data.projectdata
  const projectsettings = data.projectsettings.childMarkdownRemark.frontmatter

  const options = {
    settings: {
      autoplaySpeed: 3000,
      boxShadow: 'none',
      disableKeyboardControls: false,
      disablePanzoom: true,
      disableWheelControls: false,
      hideControlsAfter: 3000,
      lightboxTransitionSpeed: 0.3,
      lightboxTransitionTimingFunction: 'linear',
      overlayColor: 'rgba(30, 30, 30, 0.9)',
      slideAnimationType: 'fade',
      slideSpringValues: [300, 50],
      slideTransitionSpeed: 0.6,
      slideTransitionTimingFunction: 'linear',
      usingPreact: true,
    },
    caption: {
      showCaption: project.frontmatter.gallerytitleenable
    }
  }
  return (
    <>
      <Layout location={location} isOpacity={true}>
        <SDArticle type="Article" title={project.frontmatter.title} image={project.featuredimageLocal.publicURL} datepub={project.frontmatter.datepub} datemod={project.frontmatter.datemod} />
        <Seo strTitle={project.frontmatter.seotitle} strDescription={project.frontmatter.seometadesc} strImage={project.featuredimageLocal.publicURL} />
        <HeroProject
          featuredImage={project.featuredimageLocal}
          featuredImageAlt={project.frontmatter.featuredimagealt}
          title={project.frontmatter.title}
          btn1text={project.frontmatter.settingherobtn1text}
          btn1Link={project.frontmatter.buttononelink[0].menulink}
          btn1type={project.frontmatter.buttononelink[0].type}
          btn1enable={project.frontmatter.settingherobtn1enable}
          btn2Text={project.frontmatter.settingherobtn2text}
          btn2Link={project.frontmatter.buttontwolink[0].menulink}
          btn2type={project.frontmatter.buttontwolink[0].type}
          btn2enable={project.frontmatter.settingherobtn2enable} />
        <div className="w-full bg-white relative" style={{ paddingTop: "0.2px", paddingBottom: "0.2px" }}>
          <div id="main" className="absolute -top-16"></div>
          <div className="container mx-auto xs:mb-2 sm:my-16 xs:px-4 sm:px-4 md:px-10"><SimpleReactLightbox><SRLWrapper options={options}>
            {project.frontmatter.galleryone && (
              project.frontmatter.galleryone.length > 0 && (
                <div className="sm:mb-16">
                  <ModalGallery gallery={project.frontmatter.galleryone} enabletitle={project.frontmatter.gallerytitleenable} />
                </div>
              ))}
            <div className="flex justify-start pt-3 pr-1 xs:pb-3 sm:pb-16 flex-col-reverse md:flex-row-reverse mr-2 md:mr-0 relative" >
              <div id="strategy" className="absolute -top-20"></div>
              <div className="relative self-auto flex-1 w-full md:w-1/2 grid projectsection">

                <div className="boxShadowRight"></div>
                <div className="relative shadow projectsection grid cursor-pointer">
                  <Img loading="eager" fluid={project.challengeimageLocal.childImageSharp.fluid} alt={project.frontmatter.challengetitle} style={{ aspectRatio: "3 / 1", gridArea: "1/1", objectFit: "cover", width: "100%" }} className="projectsectionimage" />
                </div>

              </div>
              <div className="flex justify-start w-full md:w-1/2 md:mr-6">
                <div className="cmscontent pb-3 md:pb-0 w-full">
                  <ProjectTabs challenge={project.frontmatter.challenge} solution={project.frontmatter.solution} process={project.frontmatter.process} projectinfo={project.frontmatter.projectinfo} />
                </div>
              </div>
            </div>
            {project.frontmatter.gallerytwo && (
              project.frontmatter.gallerytwo.length > 0 && (
                <div className="sm:mb-16">
                  <ModalGallery gallery={project.frontmatter.gallerytwo} />
                </div>
              ))}
            {project.servicesimageLocal &&
              <>
                <div className="flex justify-start pt-3 pr-1 xs:pb-3 sm:pb-16 flex-col-reverse md:flex-row-reverse mr-2 md:mr-0">
                  <div id="services" className="absolute -top-20"></div>
                  <div className="relative self-auto flex-1 w-full md:w-1/2 grid projectsection">

                    <div className="boxShadowRight"></div>
                    <div className="relative shadow projectsection grid cursor-pointer">
                      <Img loading="eager" fluid={project.servicesimageLocal.childImageSharp.fluid} alt={project.frontmatter.servicestitle} style={{ aspectRatio: "3 / 1", gridArea: "1/1", objectFit: "cover", width: "100%" }} className="projectsectionimage" />
                    </div>

                  </div>
                  <div className="flex justify-start w-full md:w-1/2 md:mr-6">
                    <div className="cmscontent my-auto pb-3 md:pb-0">
                      <ReactMarkdown linkTarget="_blank">
                        {project.frontmatter.services}
                      </ReactMarkdown>
                    </div>
                  </div>
                </div>
              </>
            }
            {project.frontmatter.gallerythree && (
              project.frontmatter.gallerythree.length > 0 && (
                <div className="sm:mb-16">
                  <ModalGallery gallery={project.frontmatter.gallerythree} />
                </div>
              ))}
          </SRLWrapper></SimpleReactLightbox>
            {project.frontmatter.beforeafter && (
              <ProgressGallery gallery={project.frontmatter.beforeafter} controls={project.frontmatter.beforeaftercontrols} indicators={project.frontmatter.beforeafterindicators} interval={project.frontmatter.beforeafterinterval} />
            )}
          </div>
          <WidgetProjects title={projectsettings.settingprojmore} currentProject={project.frontmatter.title} />
        </div>
      </Layout>
    </>
  );
};

export default ProjectPost;

export const query = graphql`
  query($slug: String!) {
    projectsettings: file(relativePath: {eq: "settings/projects.md"}) {
      id
      childMarkdownRemark {
          frontmatter {
            settingprojmore
          }
      }
    }
    projectdata: markdownRemark(fields: { slug: { eq: $slug } }) {
      challengeimageLocal {
        publicURL
        id
        childImageSharp {
          fluid(maxWidth: 1024, srcSetBreakpoints: [640, 768, 1024], quality: 75) {
            ...GatsbyImageSharpFluid_withWebp 
          }
        }
      }
      servicesimageLocal {
        publicURL
        id
        childImageSharp {
          fluid(maxWidth: 1024, srcSetBreakpoints: [640, 768, 1024], quality: 75) {
            ...GatsbyImageSharpFluid_withWebp 
          }
        }
      }
      featuredimageLocal {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            layout: FULL_WIDTH
          )
        }
        ext
        publicURL
      }
      frontmatter {
        featuredimagealt
        datepub(formatString: "DD MMMM, YYYY")
        datemod(formatString: "DD MMMM, YYYY")
        title
        challenge
        challengetitle
        solution
        process
        projectinfo
        services
        servicestitle
        seotitle
        seometadesc
        gallerytitleenable
        beforeaftercontrols
        beforeafterindicators
        beforeafterinterval
        settingherobtn1text
        settingherobtn1enable
        settingherobtn2text
        settingherobtn2enable
        buttononelink {
          menulink
          type
        }
        buttontwolink {
          menulink
          type
        }
        galleryone {
          galleryimageLocal {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                layout: FULL_WIDTH
              )
              fluid(maxWidth: 1536, srcSetBreakpoints: [640, 768, 1024, 1280], quality: 65) {
                ...GatsbyImageSharpFluid_withWebp 
              }
            }
            ext
            publicURL
            id
          }
          gallerytitle
        }
        gallerytwo {
          galleryimageLocal {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                layout: FULL_WIDTH
              )
              fluid(maxWidth: 1536, srcSetBreakpoints: [640, 768, 1024, 1280], quality: 65) {
                ...GatsbyImageSharpFluid_withWebp 
              }
            }
            ext
            publicURL
            id
          }
          gallerytitle
        }
        gallerythree {
          galleryimageLocal {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                layout: FULL_WIDTH
              )
              fluid(maxWidth: 1536, srcSetBreakpoints: [640, 768, 1024, 1280], quality: 65) {
                ...GatsbyImageSharpFluid_withWebp 
              }
            }
            ext
            publicURL
            id
          }
          gallerytitle
        }
        beforeafter {
          beforeimageLocal {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                layout: FULL_WIDTH
              )
            }
            ext
            publicURL
            id
          }
          afterimageLocal {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                layout: FULL_WIDTH
              )
            }
            ext
            publicURL
            id
          }
          aftertitle
          beforetitle
        }
      }
    }
  }
`;
