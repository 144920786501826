import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown'

import Button from './Button'

const ProjectTabs = ({ challenge, solution, process, projectinfo }) => {

    const [tab, setTab] = useState("Challenge")

    const handleClick = (tabName) => {
        setTab(tabName);
        setPromptButton(false);
    }

    const tabs = ["Challenge", "Solution", "Process", "ProjectInfo"]

    const [scroll, setScroll] = useState(0)
    const isScroll = () => {
        setScroll(window.pageYOffset);
        setPromptButton(false);
    }
    useEffect(() => {
        window.addEventListener("scroll", isScroll, { passive: true })
        return () => {
            window.removeEventListener("scroll", isScroll, { passive: true })
        }
    }, [])

    let [promptButton, setPromptButton] = useState(false)
    useEffect(() => {
        const timer =
            setTimeout(() => {
                setPromptButton(true);
            }, 4000);
        return () => clearTimeout(timer);
    }, [tab, scroll]);

    const nextClick = () => {
        let currentIndex = tabs.indexOf(tab)
        if (currentIndex === 3) { currentIndex = -1 };
        setTab(tabs[currentIndex + 1]);
        setPromptButton(false);
    };

    useEffect(() => {
        const timer =
            setTimeout(() => {
                nextClick();
            }, 14000);
        return () => clearTimeout(timer);
    });

    return (
        <>
            {/* Tabs */}
            <div className="tab flex flex-row flex-wrap">
                <div className="xs:mr-3 sm:mr-3 lg:mr-5 text-xs sm:text-sm lg:text-base">
                    <button className={tab === "Challenge" ? " border-b-2 border-template-greenDark pb-1 focus:outline-none" : "border-b-0 pb-1 opacity-60 focus:outline-none"} onClick={() => handleClick("Challenge")}>Challenge</button>
                </div>
                <div className="xs:mr-3 sm:mr-3 lg:mr-5 text-xs sm:text-sm lg:text-base">
                    <button className={tab === "Solution" ? " border-b-2 border-template-greenDark pb-1 focus:outline-none" : "border-b-0 pb-1 opacity-60 focus:outline-none"} onClick={() => handleClick("Solution")}>Solution</button>
                </div>
                <div className="xs:mr-3 sm:mr-3 lg:mr-5 text-xs sm:text-sm lg:text-base">
                    <button className={tab === "Process" ? " border-b-2 border-template-greenDark pb-1 focus:outline-none" : "border-b-0 pb-1 opacity-60 focus:outline-none"} onClick={() => handleClick("Process")}>Process</button>
                </div>
                <div className="text-xs sm:text-sm lg:text-base">
                    <button className={tab === "ProjectInfo" ? "border-b-2 border-template-greenDark pb-1 focus:outline-none" : "border-b-0 pb-1 opacity-60 focus:outline-none"} onClick={() => handleClick("ProjectInfo")}>Project Information</button>
                </div>
            </div>

            {/* Tab Content */}
            <div className="my-3">
                <div id="Challenge" className={tab === "Challenge" ? "block" : "hidden"}>
                    <ReactMarkdown linkTarget="_blank">
                        {challenge}
                    </ReactMarkdown>
                </div>

                <div id="Solution" className={tab === "Solution" ? "block" : "hidden"}>
                    <ReactMarkdown linkTarget="_blank">
                        {solution}
                    </ReactMarkdown>
                </div>

                <div id="Process" className={tab === "Process" ? "block" : "hidden"}>
                    <ReactMarkdown linkTarget="_blank">
                        {process}
                    </ReactMarkdown>
                </div>
                <div id="ProjectInfo" className={tab === "ProjectInfo" ? "block" : "hidden"}>
                    <ReactMarkdown linkTarget="_blank">
                        {projectinfo}
                    </ReactMarkdown>
                </div>
                <div className={promptButton ? "animate-pulse mt-4" : "mt-4"}>
                    <Button radius={"0px"} btnStyle={"dark"} onClick={() => nextClick()} fontWeight={400}>Next</Button>
                </div>
            </div>
        </>
    );
};

export default ProjectTabs;
