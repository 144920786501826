// Node Modules
import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Link } from 'gatsby';

// Assets
import Button from './Button';
import IconDownArrow from '../images/IconDownArrow';

const HeroProject = ({ featuredImage, featuredImageAlt, btn1text, btn1Link, btn1type, btn1enable = false, btn2Text, btn2Link, btn2type, btn2enable = false, title }) => {

    return (
        <>
            <section className="h-screen">
                <div className="fixed top-0 w-full h-screen" style={{ display: "grid" }} >
                    <GatsbyImage image={featuredImage.childImageSharp.gatsbyImageData}
                        alt={featuredImageAlt}
                        className="row-start-1 col-start-1"
                        layout="fullWidth"
                        aspectratio={3 / 1}
                        style={{ gridArea: "1 / 1" }}
                    />
                </div>


                <div className="fixed bottom-0 w-full">
                    <div className="w-full flex justify-center mb-6">
                        <div className="py-1 px-3 w-max">
                            <h1 className="text-center text-2xl md:text-4xl lg:text-4xl font-normal text-white leading-tight tracking-wider">
                                {title}
                            </h1>
                        </div>
                    </div>
                    <div className="left-0 w-full flex flex-col justify-center z-10 sm:flex-row">
                        {btn1enable &&
                            <div className="px-3 pb-2 sm:pb-0 flex justify-center">
                                {btn1type === "sectionlink" ?
                                    <AnchorLink href={btn1Link}>
                                        <Button radius={"0px"} btnStyle={"light"} opacity={0.80}>{btn1text}</Button>
                                    </AnchorLink>
                                    :
                                    <Link to={btn1Link}>
                                        <Button radius={"0px"} btnStyle={"light"} opacity={0.80}>{btn1text}</Button>
                                    </Link>
                                }
                            </div>
                        }
                        <div className="flex justify-center h-full pb-1">
                            <div className="w-8 bg-template-greenDark70 hover:bg-template-greyLight70" style={{padding: "0.4rem"}}>
                            <AnchorLink href="#main" aria-label="Scroll Down To See More">
                                <IconDownArrow width={20} className="text-template-whiteLight" />
                            </AnchorLink>
                            </div>
                        </div>
                        {btn2enable &&
                            <div className="px-3 flex justify-center">
                                {btn2type === "sectionlink" ?
                                    <AnchorLink href={btn2Link}>
                                        <Button radius={"0px"} btnStyle={"light"} opacity={0.80}>{btn2Text}</Button>
                                    </AnchorLink>
                                    :
                                    <Link to={btn2Link}>
                                        <Button radius={"0px"} btnStyle={"light"} opacity={0.80}>{btn2Text}</Button>
                                    </Link>
                                }
                            </div>
                        }
                    </div>
                </div>
            </section>
        </>
    );
};

export default HeroProject;
