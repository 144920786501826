// Node Modules
import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import { Carousel, CarouselItem } from "react-bootstrap";

const ProgressGallery = (props) => {

  return (
    <>
      <section className="pt-2">
        <Carousel className="" controls={props.controls} indicators={props.indicators} fade={false} interval={props.interval}>
          {props.gallery.map(
            (item, index) => {
              return (
                <CarouselItem key={index} className="">
                  <div className="h-600 md:h-96 relative grid grid-cols-1 md:grid-cols-2 gap-4 max-w-6xl mx-auto" >
                    <GatsbyImage image={item.beforeimageLocal.childImageSharp.gatsbyImageData} alt={item.beforetitle}
                      className="row-start-1 col-start-1"
                      layout="fullWidth"
                      aspectratio={3 / 1}
                    />
                    <div className="transition-all duration-75 transform -translate-y-28 md:-translate-y-32 row-start-1 col-start-1 relative grid place-items-center" >
                      <div className="bg-template-black70 bg-opacity-80 h-auto container mx-auto w-full">
                        <div className="py-1 px-9">
                          <h2 className="text-center text-sm md:text-lg font-extralight text-white tracking-wider leading-tight">
                            {item.beforetitle}
                          </h2>
                        </div>
                      </div>
                    </div>
                    <GatsbyImage image={item.afterimageLocal.childImageSharp.gatsbyImageData} alt={item.aftertitle}
                      className="row-start-2 md:row-start-1 col-start-1 md:col-start-2"
                      layout="fullWidth"
                      aspectratio={3 / 1}
                    />
                    <div className="transition-all duration-75 transform -translate-y-28 md:-translate-y-32 row-start-2 md:row-start-1 col-start-1 md:col-start-2 relative grid place-items-center" >
                      <div className="bg-template-black70 bg-opacity-80 h-auto container mx-auto w-full">
                        <div className="py-1 px-9">
                          <h2 className="text-center text-sm md:text-lg font-extralight text-white tracking-wider leading-tight">
                            {item.aftertitle}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </CarouselItem>
              );
            }
          )}
        </Carousel>
      </section>
    </>
  );
};

export default ProgressGallery;
